import { render, staticRenderFns } from "./GdtReward.vue?vue&type=template&id=4cce153b&scoped=true&"
import script from "./GdtReward.vue?vue&type=script&lang=js&"
export * from "./GdtReward.vue?vue&type=script&lang=js&"
import style0 from "./GdtReward.vue?vue&type=style&index=0&id=4cce153b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cce153b",
  null
  
)

export default component.exports