<template>
  <div class="boardOfDirectors">
    <div class="Board_of_directors">
      <div class="logo">
        <div class="join_left"
             @click="donation">
          <div class="donation">
            <span>{{$t('lang.swap57')}}</span>
          </div>
        </div>
      </div>
      <div class="BoardOfDirectors_content">
        <div class="rights_and_interests">
          <div class="subtitle">
            <span>{{$t('lang.swap58')}}</span>
          </div>
          <div class="detailed">
            <div class="detailed_left">
              <div class="swan">
                <img class="image"
                     src="@/assets/img/logo.jpg" />
                <div>
                  <span>PHO</span>
                </div>
              </div>
              <div class="quantity">
                <span>{{reward}}</span>
              </div>
            </div>
            <div class="receives_top"
                 v-if="reward!=0"
                 @click="getReward">
              <span>{{$t('lang.swap66')}}</span>
            </div>

            <div class="receives"
                 v-else>
              <span>{{$t('lang.swap66')}}</span>
            </div>
          </div>
        </div>

        <div class="BoardOfDirectors_list">
          <div class="subtitle">
            <span>{{$t('lang.swap59')}}</span>
          </div>
          <div>
            <div class="record"
                 v-for="(item,index) of donateList"
                 :key="index">
              <span>{{item}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { coin } from '../../api/index';
export default {
  data () {
    return {
      reward: 0,
      donateList: [],
    };
  },
  mounted () { },
  methods: {
    getReward () {
      let data = {
        chain_id: this.net,
        coin_id: 2,
      };
      coin.rewardOut(data, this.account).then(res => {
        if (res.code == 200) {
          this.errmsg = this.$t('lang.swap67');
          this.showWhite = true;
        }
      });
    },
    donation () {
      this.$toast(this.$t('lang.swap134'));
    },
    init () {
      coin.memberList(this.account).then(res => {
        if (res.code == 200) {
          this.donateList = res.data.slice(0, 12);
        }
      });
      coin.rewardList(this.account).then(res => {
        if (res.code == 200) {
          this.reward = res.data[1].usable_str;
        }
      });
    },
  },
  created () {
    this.init();
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath']),
  },
};
</script>

<style lang="less" scoped>
.boardOfDirectors {
  padding: 0 16px;
  .Board_of_directors {
    border: 1px solid #3399ff;
    background: #fff;
    max-width: 400px;
    margin: 0 auto 0;

    .logo {
      background: url('~@/assets/img/boardOfDirectors.png');
      background-size: 100% 110%;
      padding: 200px 0 40px;
      .join_left {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 18px;

        .donation {
          font-size: 20px;
          font-weight: bold;
          color: #3399ff;
          background: #ffffff;
          border-radius: 16px;
          padding: 6px 38px;
          text-align: center;
        }
      }
    }
    .BoardOfDirectors_content {
      min-height: 400px;
      background: #fffefe;
      border-radius: 50px 50px 0px 0px;
      border: 1px solid #3399ff;

      padding: 36px 30px 26px;
      box-sizing: border-box;

      .rights_and_interests {
        .subtitle {
          font-size: 20px;
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          font-weight: bold;
          color: #333333;
        }
        .detailed {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          .detailed_left {
            display: flex;
            align-items: center;
            .quantity {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 58px;
              height: 58px;
              font-size: 30px;
              font-family: DINAlternate-Bold-, DINAlternate-Bold;
              font-weight: normal;
              color: #986454;
              border-radius: 50%;
              margin-left: 12px;
            }
            .swan {
              font-size: 12px;
              font-family: PingFangSC-Semibold-, PingFangSC-Semibold;
              font-weight: normal;
              color: #333333;
              text-align: center;

              .image {
                width: 26px;
                height: 26px;
              }
            }
          }
          .receives_top {
            font-size: 20px;
            color: #986454;
            background: url('../../assets/img/receive.png') no-repeat;
            background-size: 100% 100%;
            padding: 10px 24px 10px 48px;
          }
          .receives {
            font-size: 20px;
            color: #333333;
            background: url('../../assets/img/receives.png') no-repeat;
            background-size: 100% 100%;
            padding: 10px 24px 10px 48px;
          }
        }
      }
      .BoardOfDirectors_list {
        margin-top: 36px;
        .subtitle {
          font-size: 18px;
          font-family: PingFangSC-Semibold-, PingFangSC-Semibold;
          font-weight: normal;
          color: #333333;
        }
        .record {
          font-size: 12px;
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          font-weight: normal;
          color: #333333;
          margin-top: 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 700px) {
  .boardOfDirectors {
    padding: 70px 0 100px;
    .Board_of_directors {
      overflow: hidden;
      border-radius: 16px !important;
    }
  }
}
</style>