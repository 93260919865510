import communityAbi from '../config/abi/community.json'
import Web3 from 'web3'
import store from '../store';
//存入
function stake(myAddress, amount, transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(communityAbi, '0x950B8706Cb876689CBacabEEF14f4385cD991C50');
        let hash = ""
        crossingOkContractInstance.methods.stake(amount).send({ from: myAddress })
            .on('transactionHash', res => {
                transactionsDetail['hash'] = res
                store.commit('SETTRANSACTIONS', transactionsDetail);
                resolve(res)
            }).on('confirmation', res => {
            }).on('error', res => {
                reject(res)
            })
    })
}


//获取当前质押数量
function stakeInfo(myaddress){
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(communityAbi, '0x950B8706Cb876689CBacabEEF14f4385cD991C50');
        crossingOkContractInstance.methods.stakeInfo(myaddress).call().then(res => {
            resolve(res)
        })
    })
}

export default {
    stake,
    stakeInfo,
}