<template>
  <div class="shoyi">
    <div style="display: flex; width: 130px;justify-content: space-between;">
      <topbutton router="snowguan" style="margin-top: 6px;"></topbutton>
      <p class="s_title">{{ $t('lang.d58') }}</p>
    </div>

    <div class="s_boxs">
      <div class="b_title">
        <span>{{ $t('lang.d87') }}</span>
        <span style="margin-left: 50px;">{{ $t('lang.d88') }}</span>
        <span>{{ $t('lang.d18') }}</span>
        <span>{{ $t('lang.d89') }}</span>
      </div>
      <div class="c_wlist" v-if="shoyilist.length == 0">
        <img src="@/assets/imgsnowball/wujilu.png" alt="">
        <p>{{ $t('lang.d57') }}</p>
      </div>
      <!-- <div class="boxx" v-else>
          <div v-for="item in shoyilist">
          <div style="width: 30%;">{{ item.created_time }}</div>
          <span style="color: #fff;">{{item.behavior_txt}}</span>
          <span style="color: #fff;">{{ item.token_name }}</span>
          <span style="color: #39dba3ff;">＋{{ item.usable_change.toFixed(2)}}</span>
          
        </div>
        </div> -->
      <van-list v-else v-model="loading" :finished="finished" :finished-text="`${$t('lang.d145')}`" @load="onLoad"
        class="boxx">
        <div v-for="item in shoyilist" class="boxs">
          <div style="width: 30%;">{{ item.created_time }}</div>
          <span style="color: #000;">{{ item.behavior_txt }}</span>
          <span style="color: #000;">{{ item.token_name }}</span>
          <span style="color: #39dba3ff;">＋{{ item.usable_change.toFixed(2) }}</span>

        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { moneymask } from '@/api/index';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
    topbutton
  },
  data() {
    return {
      shoyilist: [

      ],
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      isrun: 0
    }
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  created() {
    this.getshoyi()
  },
  watch: {
    account() {
      //获取账号
      this.getshoyi();
    },
    lang() {
      this.getshoyi();
    }
  },
  methods: {
    getshoyi() {

      let data = {
        page: 1,
        page_size: 10,
        behavior_arr: '[12,13,16,17]',
        token_id: 0
      }
      moneymask.getjiluliebiao(data, this.account).then(res => {
        console.log(res);
        this.shoyilist = res.data.list
      })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.isrun = this.shoyilist.length;
        this.page_size += 10;

        let data = {
          page: 1,
          page_size: this.page_size,
          behavior_arr: '[12,13,16,17]',
          token_id: 0
        }
        moneymask.getjiluliebiao(data, this.account).then(res => {
          console.log(res);

          if (res.data.list.length == 0) {
            this.finished = true
          } else {
            this.shoyilist = res.data.list
          }
        })
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        // if (this.shoyilist.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);

    },
  },
}
</script>

<style lang="less" scoped>
.shoyi {
  width: 100%;

  padding: 20px;
  height: 100%;
}

.s_title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  font-family: "PingFang";


}

.s_boxs {
  width: 100%;

  border-radius: 20px;
  box-sizing: border-box;
  padding: 16px;

  background: #fff;
  border: 1px solid #E8E8E8;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .b_title {
    color: #999999ff;
    font-size: 14px;
    font-weight: 500;
    font-family: "PingFang";
  }

  .b_cont {
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
  }
}

.boxx {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
 
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
height: 92px;

  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: "PingFang";
  }
}</style>