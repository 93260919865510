<template>
  <div class="equityPool">
    <div class="title">
      <span>{{ $t('lang.swap81') }}</span>
    </div>
    <div class="total">
      <div>
        <div>
          <span>{{ $t('lang.swap174') }}</span>
        </div>
        <div class="total_value">
          <!-- <span class="special">{{significantDigits((total*1).toFixed(6))}}</span> -->
          <span class="special">{{ total_distribution }}</span>
          <span>PHO</span>
        </div>
      </div>
      <div>
        <div>
          <span>{{ $t('lang.swap175') }}</span>
        </div>
        <div class="total_value">
          <!-- <span class="special">{{significantDigits((unclaimed.usable*1).toFixed(6))}}</span> -->
          <span class="special">{{ usable_str }}</span>

          <span>PHO</span>
        </div>
      </div>
      <van-button type="primary"
                  class="loginBtn"
                  @click="handleLogin">{{ $t('lang.swap177') }}</van-button>
    </div>

    <div class="title"
         :style="{ 'margin-top': '20px' }">
      <span>{{ $t('lang.swap202') }}</span>
    </div>

    <div class="total">
      <!-- <div class="special_title">
        <span>{{ $t('lang.swap202') }}</span>
      </div> -->
      <div class="super_partner">
        <img src="@/assets/img/diamonds.png"
             class="total_img" />
        <!-- <span>{{ $t('lang.swap178') }}</span> -->
        <van-rate v-model="star_card_level"
                  color="#724DC5"
                  readonly />
      </div>
      <div>
        <div>
          <span>{{ $t('lang.swap219') }}</span>
        </div>
        <div class="total_value">
          <!-- <span class="special">{{significantDigits((received.usable*1).toFixed(6))}}</span> -->
          <span class="special">{{ significantDigits(tvl,2) }}</span>
          <span>USDT</span>
        </div>
      </div>
      <div>
        <div>
          <span>{{ $t('lang.swap220') }}</span>
        </div>
        <div class="total_value">
          <!-- <span class="special">{{significantDigits((received.usable*1).toFixed(6))}}</span> -->
          <span class="special">{{ team_tvl }}</span>
          <span>USDT</span>
        </div>
      </div>
      <div>
        <div>
          <span>{{ $t('lang.swap222') }} </span>
        </div>
        <div class="total_value">
          <!-- <span class="special">{{significantDigits((received.usable*1).toFixed(6))}}</span> -->
          <span class="special">{{ myBalance.toFixed(6) }}</span>
          <span>PHO</span>
        </div>
      </div>
    </div>

    <div class="title"
         :style="{ 'margin-top': '20px' }">
      <span>{{ $t('lang.swap193') }}</span>
    </div>

    <div class="total">
      <!-- <div :style="{ 'margin-top': '20px' }">
        <span> {{ $t('lang.swap193') }} </span>
      </div> -->
      <div class="detail_margin">
        <span> {{ $t('lang.swap194') }} </span>
      </div>
      <div class="detail_margin">
        <span>{{ $t('lang.swap195') }}</span>
      </div>
      <div class="detail_margin">
        <span>{{ $t('lang.swap196') }} </span>
      </div>
      <div class="detail_margin">
        <span> {{ $t('lang.swap197') }}</span>
      </div>
      <div class="detail_margin">
        <span>{{ $t('lang.swap198') }}</span>
      </div>
      <div class="detail_margin">
        <span> {{ $t('lang.swap199') }} </span>
      </div>
      <div class="detail_margin">
        <span> {{ $t('lang.swap200') }} </span>
      </div>
      <div class="detail_margin">
        <span>{{ $t('lang.swap201') }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import { market } from '@/api/index';
import { mapState } from 'vuex';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import tvl from '@/methods/tvl.js';
import lpPrice from '@/methods/lpPrice.js';
import { accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing, formatAmount } from '@/utils/format.js';
export default {
  data () {
    return {
      total: '',
      unclaimed: {},
      received: {},
      member: {},
      partnerData: '',
      partnerRewards: {},
      star: 0,
      cardInfo: undefined,
      total_distribution: undefined,
      star_card_level: undefined,
      self_tvl: undefined,
      team_tvl: undefined,
      rewardCoinInfo: undefined,
      usable_str: undefined,
      myBalance: 0,
      language: '',
      lpAmount: 0,
      phoPrice: undefined,
      lpPrice: 0,
      tvl: 0,
    };
  },
  methods: {
    significantDigits,
    async init () {
      if (this.account) {
        this.memberInfo();
        this.starInfo();
        this.getUserReward();
        const erc20Contract = getErc20Contract('0xC6894a787A41271271662d9553AF627B8A0717B7');
        let bal = await erc20Contract.methods.balanceOf(this.account).call();
        this.myBalance = (bal * 1) / Math.pow(10, 18);
        this.getPhoPrice();
      }
    },

    async getPhoPrice () {
      const jackPairContract = getJackPairContract('0x45AE267070B3f98f3719669976Ed4568a6b29e27');
      let reserves = await jackPairContract.methods.getReserves().call();
      let usdtAddress = '0x382bb369d343125bfb2117af9c149795c6c65c50'; //from
      let phoAddress = '0xC6894a787A41271271662d9553AF627B8A07'; //to
      if (phoAddress.toLocaleLowerCase() < usdtAddress.toLowerCase()) {
        let fromAll = reserves._reserve0 / Math.pow(10, 18);
        let toAll = reserves._reserve1 / Math.pow(10, 18);
        // console.log('1111');
        this.phoPrice = toAll / fromAll; //币的价格
      } else {
        let fromALL = reserves._reserve1 / Math.pow(10, 18);
        let toAll = reserves._reserve0 / Math.pow(10, 18);
        //  console.log('2222');
        this.phoPrice = toAll / fromALL; //币的价格
      }

      this.getLpBalance();
      // this.getLpPrice()
    },

    getLpBalance () {
      tvl.userInfo(this.account).then(res => {
        // console.log(res.amount / Math.pow(10,18), '质押的lp余额');
        this.lpAmount = res.amount / Math.pow(10, 18); //获取当前质押lp的数量
        this.getLpPrice();
      });
    },

    getLpPrice () {
      this.phoPrice = this.phoPrice * Math.pow(10, 18) + '';
      let u = 1 * Math.pow(10, 18) + '';
      lpPrice.LPPrice('0x45ae267070b3f98f3719669976ed4568a6b29e27', u, this.phoPrice).then(res => {
        this.lpPrice = res / Math.pow(10, 18);
        this.tvl = accMul(this.lpAmount, this.lpPrice)
        // console.log(this.tvl, '个人的tvl价值');
      });
    },
    // 已领取
    // 取出
    handleLogin () {
      if (this.account == '') return;
      if (this.rewardCoinInfo.usable_str == 0) {
        this.$toast(this.$t('lang.swap218'));
        return;
      }
      let data = {
        coin_id: this.rewardCoinInfo.coin_id,
      };
      market.marketRewardOut(data, this.account, this.language).then(res => {
        if (res.code == 200) {
          this.$toast(this.$t('lang.swap67'));
        } else {
          this.$toast(res.msg);
        }
        this.init();
        // console.log(res, '权益池取出');
      });
    },
    //会员信息
    async memberInfo () {
      this.language = await localStorage.getItem('lang');
      market.marketMemberInfo(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.member = res.data;
        }
      });
    },

    //权益卡信息
    async starInfo () {
      this.language = await localStorage.getItem('lang');
      market.marketStarCardInfo(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.cardInfo = res.data;
          this.total_distribution = this.cardInfo.total_distribution;
          this.star_card_level = this.cardInfo.star_card_level;
          this.self_tvl = this.cardInfo.self_tvl;
          this.team_tvl = this.cardInfo.team_tvl;
        }
      });
    },

    async getUserReward () {
      this.language = await localStorage.getItem('lang');
      market.rewardUserRewards(this.account, this.language).then(res => {
        for (let i of res.data) {
          if (i.coin_id == 3) {
            this.rewardCoinInfo = i;
          }
        }
        this.usable_str = this.rewardCoinInfo.usable_str;
      });
    },
    significantDigits,
  },
  created () {
    this.init();
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.equityPool {
  background: #ffffff;
  // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  font-size: 18px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  border-radius: 16px;
  padding: 26px 16px 30px;
  margin: 20px;
  .title {
    font-size: 20px;
  }
  .total {
    font-size: 14px;

    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 16px;
    padding: 14px 18px;
    margin-top: 20px;
    .loginBtn {
      width: 100%;
      font-size: 18px;

      color: #ffffff;
      border-radius: 18px;
      margin: 20px 0;
    }
    .total_value {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 20px;
      span {
        margin-right: 6px;
      }
    }
    .super_partner {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #3399ff;
      margin-bottom: 10px;
      .total_img {
        width: 124px;
        height: 108px;
      }
    }
    .special_title {
      font-size: 20px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .special {
      font-size: 18px;
      color: #3399ff;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }
  }
}

.detail_margin {
  margin-top: 10px;
  span {
    font-size: 10px;
  }
}
</style>