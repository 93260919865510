<template>
  <div class="FlashExchange">
    <div class="head">
      <img class="head_img"
           src="@/assets/img/FlashExchange.png" />
      <span>{{ $t('lang.swap1') }}</span>
    </div>

    <div class="content">
      <div class="content_exchange">
        <div class="title">
          <span>{{ $t('lang.swap4') }}</span>
          <span class="type">PHO</span>
        </div>
        <div>
          <van-field class="tokenInput"
                     v-model="Input"
                     placeholder="0"
                     disabled />
        </div>
        <div class="button">
          <van-button type="primary"
                      class="nothing_swapBtn"
                      loading-text="Waiting..."
                      :loading="showApprove"
                      @click="confirmRemoval"
                      v-if="allowanceToRouter">{{ $t('lang.swap14') }}</van-button>
          <van-button type="primary"
                      class="nothing_swapBtn"
                      loading-text="Waiting..."
                      :loading="supplyLoadingBtn"
                      @click="onchange"
                      v-else>{{ $t('lang.swap77') }}</van-button>
        </div>
      </div>

      <div class="content_tips">
        <div class="tips">
          <div class="subtitle">
            <span>{{ $t('lang.swap129') }}：</span>
          </div>
          <span>{{ $t('lang.swap130') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getErc20Contract } from '@/utils/contractHelp';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import { mapState } from 'vuex';
import { useErc20Contract } from '@/utils/useContract';
import exchange from '../../methods/exchange.js';
import { MaxUint256 } from '@ethersproject/constants';
export default {
  data () {
    return {
      Input: '',
      allowanceToRouter: '',
      supplyLoadingBtn: false,
      showApprove: false
    };
  },
  methods: {
    hanldeInput () { },

    // 是否需要显示授权按钮
    async handleApproveBtnShow () {
      if (this.account) {
        const erc20Contract = getErc20Contract('0x1676BDdf162A800cCb5f9e81b0F402A33c51e06c');
        const to = '0x3b97DceBb54f36D37c8a483306757b977e5F5CB3';
        const allowance = await erc20Contract.methods.allowance(this.account, to).call();
        if (allowance == 0) {
          this.allowanceToRouter = true;
        } else {
          this.allowanceToRouter = false;
        }
      }
    },
    confirmRemoval () {
      //授权
      if (this.Input == '') {
        this.$toast(this.$t('lang.swap11'));
        return;
      }
      this.showApprove = true
      const erc20Contract = useErc20Contract('0x1676BDdf162A800cCb5f9e81b0F402A33c51e06c');
      const amo = MaxUint256.toString();
      erc20Contract.methods
        .approve('0x3b97DceBb54f36D37c8a483306757b977e5F5CB3', amo)
        .send({
          from: this.account,
        })
        .on('confirmation', res => {
          this.showApprove = false
          this.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.showApprove = false
          this.$toast(this.$t('lang.swap74'));
        });
    },

    onchange () {
      this.supplyLoadingBtn = true;
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap17')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };
      exchange
        .dochange(this.Input, this.account, transactionsDetail)
        .then(res => {
          this.supplyLoadingBtn = false;
        })
        .catch(err => {
          this.supplyLoadingBtn = false;
          this.$toast(this.$t('lang.swap26'));
        });
    },

    async getBalance () {
      if (this.account) {
        const erc20Contract = getErc20Contract('0x1676BDdf162A800cCb5f9e81b0F402A33c51e06c');
        const balance = await erc20Contract.methods.balanceOf(this.account).call();
        this.Input = formatAmount(balance, 18);
      }
    },
    // async handleTimerBalance() {
    //   window.clearTimeout(this.timerSwanBalance);
    //   this.timerSwanBalance = setInterval(async () => {
    //     this.getBalance();
    //     this.handleApproveBtnShow();
    //   }, 3000);
    // },
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath']),
  },
  watch: {
    account () {
      //获取账号
      // this.handleTimerBalance();
    },
  },
  async created () {
    //获取余额
    this.handleApproveBtnShow()
    this.getBalance();
    // this.handleTimerBalance();
    // this.ceshi('0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c','0x55d398326f99059fF775485246999027B3197955')
  },
  components: {},
};
</script>

<style lang="less" scoped>
.FlashExchange {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin: 20px 0;
  .head {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    // border-bottom: 1px solid #333333;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.301);
    padding: 20px 0 20px 18px;
    .head_img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
  .content {
    padding: 30px 36px 26px;
    .content_exchange {
      padding: 0 20px;
      .title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        .type {
          color: #3399ff;
        }
      }
      .tokenInput {
        background: #f8f7f7;
        border-radius: 16px;
        border: 1px solid #3399ff;
        padding: 2px 10px !important;
        margin: 8px 0 22px;
      }
      .button {
        display: flex;
        justify-content: center;
        .nothing_swapBtn {
          line-height: 0;
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          border-radius: 16px;
          padding: 4px 56px;
        }
      }
    }
    .content_tips {
      font-size: 12px;
      color: #666666;
      border: 1px solid #3399ff;
      border-radius: 16px;
      padding: 12px 36px 0;
      margin-top: 36px;
      .tips {
        margin-bottom: 10px;
        .subtitle {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>