<template>
  <div class="congress">
    <div>
      <span>{{ $t('lang.swap170') }}</span>
    </div>
    <div class="national_treasury">
      <div class="subtitle">
        <span>{{ $t('lang.swap180') }}</span>
      </div>
      <div>
        <div class="amount_money">
          <div>
            <span>{{ $t('lang.swap181') }}</span>
          </div>
          <div class="total_value">
            <span>{{significantDigits((congressData.cumulative*1).toFixed(6))}}</span>
            <span>USDT</span>
          </div>
        </div>
        <div class="amount_money">
          <div>
            <span>{{ $t('lang.swap182') }}</span>
          </div>
          <div class="total_value">
            <span>{{significantDigits((congressData.give_out*1).toFixed(6))}}</span>
            <span>USDT</span>
          </div>
        </div>
        <div class="amount_money">
          <div>
            <span>{{ $t('lang.swap183') }}</span>
          </div>
          <div class="total_value">
            <span>{{significantDigits((congressData.buy_back*1).toFixed(6))}}</span>
            <span>USDT</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { market } from '@/api/index';
import { mapState } from 'vuex';
import { significantDigits } from '@/utils/format.js';

export default {
  data () {
    return {
      language: '',
      congressData: {}
    }
  },
  watch: {
    account () {
      //获取账号
      this.init();
    },
  },
  methods: {
    async init () {
      this.language = await localStorage.getItem('lang');
      if (this.account == '') return
      market.marketCongress(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.congressData = res.data
        }
      })
    },
    significantDigits
  },
  created () {
    this.init();
  },
  mounted () { },
  computed: {
    ...mapState(['account']),
  },
}
</script>

<style lang="less" scoped>
.congress {
  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  font-size: 18px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  border-radius: 16px;
  padding: 26px 26px 30px;
  margin: 20px;
  .national_treasury {
    // background: #f8f7f7;
    box-shadow: 0px 3px 6px 1px #e4e4e4;
    border-radius: 16px;
    padding: 16px 8px;
    margin-top: 18px;
    .subtitle {
      padding-left: 10px;
      margin-bottom: 14px;
    }
    .amount_money {
      font-size: 14px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
      border-radius: 8px;
      color: #333333;
      padding: 12px 16px;
      margin-bottom: 10px;
      .total_value {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        span {
          margin-left: 4px;
        }
      }
    }
  }
}
</style>