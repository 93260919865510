<template>
  <div class="asset_pack">
    <div class="choose_box">
      <div class="heading">
        <span>{{ $t('lang.swap321') }}</span>
      </div>
      <div @click="open"
           class="choose">
        <span v-show="quantity">{{quantity}}</span>
        <span v-show="!quantity"
              style="color:#999; font-size: 14px;">{{ $t('lang.swap322') }}</span>
      </div>
      <div class="thorough_li">
        <span>{{ $t('lang.swap323') }}：</span>
        <span style=" color: #fff;">{{choosePledge.static_rate * 100 || 0}}%</span>
      </div>
      <div class="thorough_li">
        <span>{{ $t('lang.swap324') }}:</span>
        <span style=" color: #fff;">{{choosePledge.profit_rate * 100|| 0}}%</span>
      </div>
      <div class="thorough_li">
        <span>{{ $t('lang.swap325') }}:</span>
        <span style=" color: #fff;">{{choosePledge.profit_rate * choosePledge.pledge_amount || 0}}</span>
      </div>
      <div class="button"
           @click="submit">
        <span>{{ $t('lang.swap326') }}</span>
      </div>
      <div class="balance">
        <span>{{ $t('lang.swap327') }}：{{wallet.usable || 0}}</span>
      </div>
    </div>

    <div class="my_box">
      <div class="heading">
        <span>{{ $t('lang.swap328') }}</span>
      </div>
      <div v-if="holdList.length == 0"
           class="nothing"
           :finished="finished"
           finished-text=""
           @load="getHoldList">
        <img src="@/assets/img/nothingBlank.png"
             class="nothing_img" />
      </div>
      <van-list class="property"
                v-else
                v-for="(item,index) of holdList"
                :key="index">
        <div class="asset">
          <img src="@/assets/img/asset_pack.png"
               class="pack_img" />
          <span>{{item.pledge_amount}}GDT{{ $t('lang.swap305') }}</span>
        </div>
        <div class="progress">
          <span class="schedule_text">{{(item.rewarded / item.out_amoun).toFixed(0) * 100 || 0}}%</span>
          <van-progress :percentage="(item.rewarded / item.out_amoun).toFixed(0) * 100 || 0"
                        stroke-width="20"
                        color="#4cdafe"
                        track-color="#4e19a5"
                        :show-pivot="false" />
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap329') }}</span>
          <span style="color:#fff">{{item.today_static_reward + item.today_dynamic_reward}}GDT</span>
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap330') }}</span>
          <span style="color:#fff">{{item.today_static_reward}}GDT</span>
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap331') }}</span>
          <span style="color:#fff">{{item.today_dynamic_reward}}GDT</span>
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap332') }}</span>
          <span style="color:#fff">{{item.rewarded}}GDT</span>
        </div>
        <div class="property_li">
          <span>{{ $t('lang.swap333') }}</span>
          <span style="color:#fff">{{item.out_amount}}GDT</span>
        </div>
      </van-list>
    </div>
    <van-popup v-model="show"
               round
               position="bottom">
      <div class="select_box">
        <div class="select_title">
          <img style="opacity: 0;"
               src="@/assets/img/cancel.png"
               class="cancel_img" />
          <span>{{ $t('lang.swap334') }}</span>
          <img @click="closure"
               src="@/assets/img/cancel.png"
               class="cancel_img" />
        </div>
        <div v-for="(item,index) of list"
             :key="index"
             class="select_li"
             @click="quantityClick(item)">
          <span>{{item.pledge_amount}}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { coin } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data () {
    return {
      quantity: '',
      schedule: 60,
      show: false,
      list: [],
      choosePledge: {},
      wallet: {},
      holdList: [],
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
    };
  },
  created () {
    this.init();
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
  methods: {
    init () {
      if (this.account) {
        this.getList();
        this.getGdbWallet();
        this.getHoldList();
      }
    },
    getList () {
      coin.pledgeConfigs(this.account).then(res => {
        this.list = res.data;
      });
    },
    getGdbWallet () {
      coin.pledgeGdbWallet(this.account).then(res => {
        this.wallet = res.data;
      });
    },
    getHoldList () {
      let data = {
        page: this.page,
        page_size: this.pageSize
      }
      coin.pledgeHoldList(data, this.account).then((res) => {
        if (res.code == 200) {
          this.holdList = this.holdList.concat(res.data.list)
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg)
        }
      })
    },
    submit () {
      if (this.wallet.usable < this.choosePledge.pledge_amount) {
        this.$toast(this.$t('lang.swap11'));
        return;
      } else if (!this.choosePledge.pledge_amount) {
        this.$toast(this.$t('lang.swap10'));
        return;
      }
      let web3 = new Web3(window.web3.currentProvider);
      web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Do'), this.account, (err, res) => {
        let da = {
          config_id: this.choosePledge.id,
          signature: res,
        };
        coin.pledgeDo(da, this.account).then(res => {
          this.$toast(this.$t('lang.swap336'));
          this.init();
        });
      });
    },
    open () {
      this.show = true;
    },
    closure () {
      this.show = false;
    },
    quantityClick (item) {
      this.choosePledge = item;
      this.quantity = item.pledge_amount;
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.asset_pack {
  padding: 30px 0 0;
  .heading {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }
  .choose_box {
    border-radius: 30px 30px 0px 0px;
    background: #FFFFFF;
    padding: 20px;
    .choose {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      border-radius: 10px;
      background:  rgba(69, 131, 255, 1);
      padding: 14px 24px;
      margin-top: 16px;
    }
    .thorough_li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      color: #ffffffa9;
      margin-top: 20px;
    }
    .button {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      border-radius: 40px;
      background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
      box-shadow: 0px 20px 30px 0px rgba(25, 28, 50, 0.1);
      padding: 16px 0;
      margin: 46px 0 26px;
    }
    .balance {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: bold;
      color: #ffffffa9;
    }
  }
  .my_box {
    background: #FFFFFF;
    padding: 20px;
    margin-top: 10px;
    .nothing {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;

      padding: 60px 0 50px;
      .nothing_img {
        width: 200px;
      }
    }
    .asset {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      .pack_img {
        display: block;
        width: 80px;
        height: 90px;
        margin-right: 10px;
      }
    }
    .progress {
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      border: 2px solid rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.15);
      margin: 20px 0 46px;
      .schedule_text {
        position: absolute;
        left: 10px;
        top: 50%;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        z-index: 333;
        transform: translate(0, -50%);
      }
    }
    .property {
      border-radius: 10px;
      background:  rgba(69, 131, 255, 1);
      padding: 20px;
      margin-top: 26px;
      .property_li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff9d;
        margin-top: 20px;
      }
    }
  }
  .select_box {
    background: #FFFFFF;
    .select_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      color: #ffffff98;
      font-weight: bold;
      padding: 16px;

      .cancel_img {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    .select_li {
      font-size: 18px;
      color: #ffffff9d;
      font-weight: bold;
      border-top: 1px solid #343b46;
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>