<template>
  <div class="index">
    <div class="functional_region">
      <div class="region">
        <div v-for="(item,index) of functionOption"
             :key="index"
             @click="switchovers(index)"
             :class="index ==regionIndex?'special':''">
          <span>{{$t(item.name)}}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <router-view />
    </div>
  </div>
</template>

<script>
import { basic } from '../../api/index';
export default {
  data () {
    return {
      // 功能列表
      functionOption: [{ name: 'lang.swap77' }, { name: 'lang.swap78' }],
      regionIndex: '0',
    };
  },
  created () {
    this.getBasicList();
    if (this.$route.query.id) {
      this.regionIndex = this.$route.query.id;
      console.log(this.regionIndex);
    }
  },
  mounted () {

  },
  methods: {
    switchovers (index) {
      this.regionIndex = index;
      if (this.regionIndex == '0') {
        this.$router.push({ name: 'Swap' });
      } else {
        this.$router.push({ name: 'LiquidityIndex' });
      }
    },

    getBasicList () {

      let data = {
        page: 1,
        page_size: 20,
      };
      basic.getNoticeList(data, '0x3D12Bd39bB936a73575ea97dFbf308b08b84e76B', "zh-cn").then(res => {
        if (res.code === 200) {
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(80, 131, 249, 1) 0%, rgba(255, 255, 255, 1) 100%);
  min-height: 100vh;
  width: 100%;
 
}
.box {
  flex: 1;
}
.functional_region {
  padding: 20px 20px 0;

  .region {
    display: flex;
    width: 100%;
    line-height: 22px;
    font-size: 16px;
    border-radius: 30px;
 padding: 3px;
border: 1px solid rgba(255, 255, 255, 1);
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #ffffff;
    font-weight: 600;
    // background:  rgba(69, 131, 255, 1);
    border-radius: 34px;
    // margin: 20px 0;
    margin-bottom: 20px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      // width: 192rpx;
      padding: 16px 0;
    }
    .special {
      // width: 50%;
      color: #000;
      background: #fff;
      // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
      border-radius: 34px;
    }
  }
}
/deep/ .router {
  // background: #000 !important;
}
</style>