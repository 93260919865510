<template>
  <div class="member">
    <div class="grade">
      <img src="@/assets/img/NoGrade.png"
           class="grade_list">
      <div class="member_grade">
        <span>{{member.team_level_name}}</span>
      </div>
      <div class="percentage">
        <span :style='`width: ${member.team_level_id}%;`'></span>
      </div>
      <div class="time">
        <span>{{member.remain_day_str}}</span>
      </div>
    </div>
    <div class="membership"
         v-if="memberShow">
      <div class="membership_time">
        <span>会员到期达到收益返还</span>
      </div>
      <div class="quantity">
        <span class="quantity_special">1256</span>
        <span>FFT</span>
      </div>
      <div>
        <van-button type="default"
                    class="loginBtn"
                    v-if="expireShow"
                    disabled>领取</van-button>
        <van-button type="primary"
                    class="loginBtn_receive"
                    v-else
                    @click="receive">领取</van-button>
      </div>
    </div>
    <div class="membership"
         v-else>
      <div class="membership_time">
        <span>升级会员</span>
      </div>
      <div class="purchase">
        <div class="purchase_grade">
          <div @click="selectGrade">
            <span>选择会员等级</span>
            <img src="@/assets/img/drop_down.png"
                 class="drop_down">
          </div>
          <div>
            <img :src="currentLevel.img"
                 class="grade_list">
            <span>{{currentLevel.name}}</span>
          </div>
        </div>
        <div class="purchase_grade">
          <div>
            <span>需质押</span>
            <span class="quantity_special">1256</span>
          </div>
          <div>
            <span>FFT</span>
          </div>
        </div>
      </div>
      <div>
        <van-button type="primary"
                    class="loginBtn_receive"
                    @click="receive">升级</van-button>
      </div>
    </div>
    <van-action-sheet v-model="sheetShow">
      <div class="contents"
           v-for="(item,index) of actions"
           :key="index"
           @click="onSelect(item)">
        <img :src="item.img"
             class="drop_down">
        <span>{{item.name}}</span>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { market } from '@/api/index';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      expireShow: true, //会员是否到期
      sheetShow: false, //选择会员等级弹出层状态
      memberShow: false, //当前是否是会员
      member: {},
      currentLevel: { name: '青铜', img: require('@/assets/img/bronze.png') },
      actions: [{ name: '青铜', img: require('@/assets/img/bronze.png') }, { name: '白银', img: require('@/assets/img/silver.png') }, { name: '黄金', img: require('@/assets/img/gold.png') }, { name: '钻石', img: require('@/assets/img/diamonds.png') },],
    }
  },
  methods: {
    receive () {

    },
    onSelect (item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.sheetShow = false;
      this.currentLevel = item
    },
    selectGrade () {
      this.sheetShow = true
    },
    async memberInfo () {
      this.language = await localStorage.getItem('lang');
      if (this.account == '') return
      market.marketMemberInfo(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.member = res.data
        }
      })
    },
    async buyCondition () {
      this.language = await localStorage.getItem('lang');
      if (this.account == '') return
      market.marketBuyCondition(this.account, this.language).then(res => {
        if (res.code == 200) {
          // this.member = res.data
        }
      })
    },
  },
  created () {
    this.memberInfo();
  },
  watch: {
    account () {
      //获取账号
      this.memberInfo();
      this.buyCondition()
    },
  },
  computed: {
    ...mapState(['account']),
  },
}
</script>

<style lang="less" scoped>
.member {
  padding: 0 20px;
  margin: 20px 0;
  .grade {
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(64, 138, 248, 0.5019607843137255);
    border-radius: 8px;
    padding: 10px 18px 22px;
    .grade_list {
      width: 90px;
    }
    .percentage {
      width: 100%;
      height: 5px;
      background: #e9e9e9;
      border-radius: 16px;
      overflow: hidden;
      margin-top: 20px;

      span {
        display: block;
        width: 50%;
        height: 100%;
        background: #70a8fc;
      }
    }
    .member_grade {
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .time {
      font-size: 10px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-top: 20px;
    }
  }
  .membership {
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 8px;
    padding: 26px 16px;
    margin-top: 40px;
    .membership_time {
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    .quantity {
      font-size: 12px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 34px;
      .quantity_special {
        font-size: 26px;
        color: #70a8fc;
        margin-right: 6px;
      }
    }
    .loginBtn {
      width: 100%;
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333;
      // background: linear-gradient(93deg, #3f89f7 0%, #70a8fc 100%);
      border-radius: 18px;
      border: 1px solid #fefefe;
      margin-top: 34px;
    }
    .loginBtn_receive {
      width: 100%;
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #fff;
      background: linear-gradient(93deg, #3f89f7 0%, #70a8fc 100%);
      border-radius: 18px;
      border: 1px solid #fefefe;
      margin-top: 34px;
    }
    .purchase {
      .purchase_grade {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        margin-top: 20px;
        div {
          display: flex;
          align-items: center;
        }
        .grade_list {
          width: 22px;
          margin-right: 6px;
        }
        .quantity_special {
          font-size: 18px;
          color: #70a8fc;
          margin-left: 6px;
        }
        .drop_down {
          width: 8px;
          height: 4px;
          margin-left: 6px;
        }
      }
    }
  }
  .contents {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .drop_down {
      width: 22px;
      margin-right: 6px;
    }
  }
}
</style>