import phoLpAbi from '../config/abi/vgtLp.json'
import Web3 from 'web3'
import store from '../store';
//存入
function stake(myAddress, amount, transactionsDetail) {
    console.log(myAddress, amount, transactionsDetail,"aaaaaaa");
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x616ec65a7b0fa2ca9eb78ff5222148f5f7035ef3');
        crossingOkContractInstance.methods.deposit(1,amount).send({ from: myAddress })
            .on('transactionHash', res => {
                transactionsDetail['hash'] = res
                store.commit('SETTRANSACTIONS', transactionsDetail);
            }).on('confirmation', res => {
                resolve(res)``
            }).on('error', res => {
                reject(res)
            })
    })
}


//取出
function withdraw(myaddress, amount, transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x616ec65a7b0fa2ca9eb78ff5222148f5f7035ef3');
        crossingOkContractInstance.methods.withdraw(1,amount).send({ from: myaddress }).on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

//奖励数量
function earned(myAddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x616ec65a7b0fa2ca9eb78ff5222148f5f7035ef3');
        crossingOkContractInstance.methods.pendingSwan(1,myAddress).call().then(res => {
            resolve(res)
        })
    })
}

//领取奖励
function claimReward(myAddress,transactionsDetail) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x616ec65a7b0fa2ca9eb78ff5222148f5f7035ef3');
        crossingOkContractInstance.methods.harvest(1).send({ from: myAddress }).on('transactionHash', res => {
            transactionsDetail['hash'] = res
            store.commit('SETTRANSACTIONS', transactionsDetail);
        }).on('confirmation', res => {
            resolve(res)
        }).on('error', res => {
            reject(res)
        })
    })
}

//锁仓到期时间戳
function lockData(myAddress) {
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x616ec65a7b0fa2ca9eb78ff5222148f5f7035ef3');
        crossingOkContractInstance.methods.lockData(myAddress).call().then(res => {
            resolve(res)
        })
    })
}

//获取当前质押数量
function balance(myaddress){
    return new Promise(function (resolve, reject) {
        let web3 = new Web3(window.web3.currentProvider);
        let crossingOkContractInstance = new web3.eth.Contract(phoLpAbi, '0x616ec65a7b0fa2ca9eb78ff5222148f5f7035ef3');
        crossingOkContractInstance.methods.userInfo(1,myaddress).call().then(res => {
            resolve(res.amount)
        })
    })
}

export default {
    stake,
    withdraw,
    earned,
    claimReward,
    lockData,
    balance
}