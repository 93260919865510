<template>
  <div class="reward">
    <div class="contents">
      <div class="title">
        <span>{{ $t('lang.swap244') }} </span>
        <span> {{ $t('lang.swap243') }}</span>
      </div>
      <div class="quantity ">
        <span>{{white_list_reward}}</span>
        <span>{{white_list_increment}}</span>
      </div>
      <div class="line"></div>

      <div class="title">
        <span>{{ $t('lang.swap245') }}</span>
        <span>{{ $t('lang.swap243') }}</span>
      </div>
      <div class="quantity">
        <span>{{star_card_reward}}</span>
        <span>{{star_card_reward_increment}}</span>
      </div>
      <div class="line"></div>

      <div class="title">
        <span>{{ $t('lang.swap246') }}</span>
        <span> {{ $t('lang.swap243') }}</span>
      </div>
      <div class="quantity">
        <span>{{mining_reward}}</span>
        <span>{{mining_reward_increment}}</span>
      </div>
      <div class="line"></div>

      <div class="title">
        <span>{{ $t('lang.swap247') }}</span>
        <span>{{ $t('lang.swap243') }} </span>
      </div>
      <div class="quantity">
        <span>{{useReward}}</span>
        <span>{{total_increment}}</span>

      </div>
      <div>
        <van-button v-if="useReward != 0"
                    type="primary"
                    class="receive"
                    @click="receiveClick">{{ $t('lang.swap66') }}</van-button>
        <van-button v-else
                    type="primary"
                    class="prohibit"
                    disabled>{{ $t('lang.swap66') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { market } from '@/api/index';
export default {
  data () {
    return {
      receiveShow: false,
      star_card_reward: 0,
      white_list_reward: 0,
      mining_reward: 0,
      useReward: 0,
      coin_id: 0,
      white_list_increment: 0,
      star_card_reward_increment: 0,
      mining_reward_increment: 0,
      total_increment: 0,
      token_id: undefined
    };
  },
  methods: {
    init () {
      if (this.account) {
        this.gdtInfo();
        this.getUserReward();
      }
    },
    //会员信息
    async memberInfo () {
      this.language = await localStorage.getItem('lang');
      market.marketMemberInfo(this.account, this.language).then(res => {
        if (res.code == 200) {
        }
      });
    },

    //权益卡信息
    async gdtInfo () {
      this.language = await localStorage.getItem('lang');
      market.gdtInfo(this.account, this.language).then(res => {
        if (res.code == 200) {
          this.star_card_reward = res.data.star_card_reward;
          this.white_list_reward = res.data.white_list_reward;
          this.mining_reward = res.data.mining_reward;
          this.white_list_increment = res.data.white_list_increment
          this.star_card_reward_increment = res.data.star_card_reward_increment
          this.mining_reward_increment = res.data.mining_reward_increment
          this.total_increment = res.data.total_increment
          this.token_id = res.data.token_id
        }
      });
    },

    async getUserReward () {
      this.language = await localStorage.getItem('lang');
      market.rewardUserRewards(this.account, this.language).then(res => {
        for (let i of res.data) {
          if (i.coin_id == 6) {
            this.coin_id = i.coin_id;
            this.useReward = i.usable_str;
          }
        }
      });
    },
    receiveClick () {
      if (this.account == '') return;
      if (this.useReward * 1 == 0) {
        this.$toast(this.$t('lang.swap218'));
        return;
      }
      let data = {
        coin_id: this.coin_id,
      };
      market.marketRewardOut(data, this.account, this.language).then(res => {
        if (res.code == 200) {
          this.$toast(this.$t('lang.swap67'));
        } else {
          this.$toast(res.msg);
        }
        this.init();
        // console.log(res, '权益池取出');
      });
    },
  },
  created () {
    this.init();
  },
  watch: {
    account () {
      this.init();
    },
  },
  computed: {
    ...mapState(['account']),
  },
};
</script>

<style lang="less" scoped>
.reward {
  padding: 40px 20px 20px;
  // padding-top: 40px;
}
.contents {
  font-size: 14px;

  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1607843137254902);
  border-radius: 6px;
  padding: 16px 16px 30px;
  background: #fff;
  // margin-top: 40px;
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #222222;
    padding-top: 14px;
  }
  .quantity {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 14px;
  }
  .line {
    border: 1px solid #f5f5f5;
  }
  .receive {
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    border-radius: 6px !important;
    margin-top: 40px;
  }
  .prohibit {
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    color: #9b9898 !important;
    background: #d8d8d8;
    border: 1px solid #d8d8d8;
    border-radius: 6px !important;
    margin-top: 40px;
  }
}
</style>